html,
body {
  margin: 0px;
}
#crmWebToEntityForm.zcwf_lblLeft {
  width: 100%;
  padding: 25px;
  margin: 0 auto;
  box-sizing: border-box;
}

.phone-wrap {
  display: flex;
}
.phone-wrap .mobile_number_prefix {
  width: 20% !important;
  border-right: 0px !important;
  border-color: #ccc !important;
}

#crmWebToEntityForm.zcwf_lblLeft * {
  box-sizing: border-box;
}
#crmWebToEntityForm {
  text-align: left;
}
#crmWebToEntityForm * {
  direction: ltr;
}
.zcwf_lblLeft .zcwf_title {
  word-wrap: break-word;
  padding: 0px 6px 10px;
  /* padding: 0px 10px; */
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  color: #3c3c3c;
  text-transform: capitalize;
  text-align: center;
}
.zcwf_lblLeft .zcwf_col_fld input[type="text"],
.zcwf_lblLeft .zcwf_col_fld textarea .zcwf_lblLeft .zcwf_col_fld select {
  /* width: 60%; */
  width: 100%;
  border: 1px solid #ccc !important;
  resize: vertical;
  border-radius: 2px;
  float: left;
  padding: 0.5rem;
}
.zcwf_lblLeft .zcwf_col_lab {
  width: 30%;
  word-break: break-word;
  padding: 0px 6px 0px;
  margin-right: 10px;
  margin-top: 5px;
  float: left;
  min-height: 1px;
}
.zcwf_lblLeft .zcwf_col_fld {
  float: left;
  /* width: 68%; */
  width: 100%;
  padding: 0px 6px 0px;
  position: relative;
  margin-top: 5px;
}
.zcwf_lblLeft .zcwf_privacy {
  padding: 6px;
}
.zcwf_lblLeft .wfrm_fld_dpNn {
  display: none;
}
.dIB {
  display: inline-block;
}
.zcwf_lblLeft .zcwf_col_fld_slt {
  width: 60%;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 4px;
  font-size: 12px;
  float: left;
  resize: vertical;
  padding: 2px 5px;
}
.zcwf_lblLeft .zcwf_row:after,
.zcwf_lblLeft .zcwf_col_fld:after {
  content: "";
  display: table;
  clear: both;
}
.zcwf_lblLeft .zcwf_col_help {
  float: left;
  margin-left: 7px;
  font-size: 12px;
  max-width: 35%;
  word-break: break-word;
}
.zcwf_lblLeft .zcwf_help_icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: inline-block;
  background: #fff;
  border: 1px solid #ccc;
  color: #ccc;
  text-align: center;
  font-size: 11px;
  line-height: 16px;
  font-weight: bold;
  border-radius: 50%;
}
.zcwf_lblLeft .zcwf_row {
  margin: 10px 0px;
}
.zcwf_lblLeft .formsubmit {
  margin-right: 5px;
  cursor: pointer;
  color: #333;
  font-size: 12px;
}
.zcwf_lblLeft .zcwf_privacy_txt {
  width: 90%;
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-family: Arial;
  display: inline-block;
  vertical-align: top;
  color: #333;
  padding-top: 2px;
  margin-left: 6px;
}
.zcwf_lblLeft .zcwf_button {
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  background: #01052d;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
}

.zcwf_lblLeft .zcwf_button:hover {
  color: #01052d;
  background: #ffffff;
}

.zcwf_lblLeft .zcwf_button:focus {
  outline: 0;
  color: #01052d;
  background: #ffffff;
}

.zcwf_lblLeft .zcwf_tooltip_over {
  position: relative;
}
.zcwf_lblLeft .zcwf_tooltip_ctn {
  position: absolute;
  background: #dedede;
  padding: 3px 6px;
  top: 3px;
  border-radius: 4px;
  word-break: break-word;
  min-width: 100px;
  max-width: 150px;
  color: #333;
  z-index: 100;
}
.zcwf_lblLeft .zcwf_ckbox {
  float: left;
}
.zcwf_lblLeft .zcwf_file {
  width: 55%;
  box-sizing: border-box;
  float: left;
}
.clearB:after {
  content: "";
  display: block;
  clear: both;
}
@media all and (max-width: 600px) {
  .zcwf_lblLeft .zcwf_col_lab,
  .zcwf_lblLeft .zcwf_col_fld {
    width: auto;
    float: none !important;
  }
  .zcwf_lblLeft .zcwf_col_help {
    width: 40%;
  }
}
